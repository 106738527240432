/* fuzzy-bubbles-regular - latin */
@font-face {
  font-family: 'Fuzzy Bubbles';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/fuzzy-bubbles-v3-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('fonts/fuzzy-bubbles-v3-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/fuzzy-bubbles-v3-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/fuzzy-bubbles-v3-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('fonts/fuzzy-bubbles-v3-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/fuzzy-bubbles-v3-latin-regular.svg#FuzzyBubbles') format('svg'); /* Legacy iOS */
}
/* fuzzy-bubbles-700 - latin */
@font-face {
  font-family: 'Fuzzy Bubbles';
  font-style: normal;
  font-weight: 700;
  src: url('fonts/fuzzy-bubbles-v3-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('fonts/fuzzy-bubbles-v3-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/fuzzy-bubbles-v3-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/fuzzy-bubbles-v3-latin-700.woff') format('woff'), /* Modern Browsers */
       url('fonts/fuzzy-bubbles-v3-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/fuzzy-bubbles-v3-latin-700.svg#FuzzyBubbles') format('svg'); /* Legacy iOS */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F9F9F9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.big-logo {
  width: 120px;
  height: 120px;
  transform: rotate(-2deg);
  user-select: none;
}

.full-page {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.about-holder {
  text-align: center;
}

.postcard {
  position: relative;
  width: 80%;
  height: 70%;
  max-width: 850px;
  max-height: 750px;
  padding: 30px;
  border-radius: 15px;
  background-color: #ffffff;
  box-shadow: 5px 2px 16px 27px rgba(238,238,238,0.50);
}

.to-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.field-and-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
  border-bottom-color: #F9F9F9;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  padding-bottom: 10px;
}

.field-and-label-top {
  justify-content: flex-start;
  align-items: flex-start;
}

.field-and-label-spacer {
  margin-top: 20px;
}

.rounded-button {
  background-color: #77D9E6;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.disabled-button {
  background-color: #F9F9F9;
}

.disabled-button:hover {
  transform: none !important;
}

.button {
  background-color: #77D9E6;
  padding: 15px;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 18px;
  border-radius: 8px;
  user-select: none;
  cursor: pointer;
  font-weight: 700;
  color: rgba(255,255,255,0.85);
  display: inline-block;
  transition: all 0.3s ease-in-out;
}

.button:hover {
  color: rgba(255,255,255,1);
  transform: scale3d(1.1,1.1,1.1);
}

h1, h2 {
  font-family: "Fuzzy Bubbles";
  margin: 0px;
  font-weight: 900;
}

.centered-text {
  text-align: center;
}

.remaining-holder {
  position: absolute;
  background-color: #62C9D8;
  color: #ffffff;
  font-weight: bold;
  padding: 8px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  top: 50px;
  right: 40px;
  font-size: 14px;
}

.subtext {
  opacity: 0.45;
  font-family: "Fuzzy Bubbles";
  font-size: 16px;
  margin-top: 10px;
}

.text-field {
  font-family: "Fuzzy Bubbles";
  font-weight: 700;
  font-size: 20px;
  height: 26px;
  border: none;
  margin-top: 5px;
  outline: none !important;
  margin-left: 10px;
  flex: 1;
}

.to-when {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.postcard-buttons {
  position: absolute;
  bottom: 30px;
  left: 30px;
  right: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.button-icon {
  height: 20px;
}

.date-options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 10px;
  margin-top: -2px;
}

.date-select {
  margin-right: 10px;
  margin-bottom: 10px;
  border-color: #F9F9F9;
  border-style: solid;
  border-width: 3px;
  padding: 8px;
  user-select: none;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;
  font-family: "Fuzzy Bubbles";
  font-weight: 900;
  border-radius: 12px;
  color: #737372;
}

.date-select-on {
  border-color: #77D9E6;
  color: #000000;
  opacity: 1;
}

.address-label {
  font-weight: 700;
  padding: 10px;
  font-size: 15px;
  user-select: none;
  border-radius: 6px;
  background-color: #F9F9F9;
}

.to-field {
  margin-left: 5px;
  border-bottom-color: #F9F9F9;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  font-family: "Fuzzy Bubbles";
  font-weight: 700;
  height: 30px;
  flex: 1;
}

.to-field::placeholder {
  color: #F9F9F9;
}

.postcard-text-container {
  position: absolute;
  overflow: hidden;
  top: 170px;
  left: 50px;
  right: 50px;
  bottom: 100px;
}

.postcard-lines {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
}

.postcard-line {
  height: 3px;
  position: absolute;
  top: 52px;
  right: 0px;
  left: 0px;
  background-color: #F9F9F9;
}

.postcard-text {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 2;
  font-family: "Fuzzy Bubbles";
  font-weight: 700;
  font-size: 30px;
  line-height: 60px;
  outline: none !important;
}

::selection {
  background: #77D9E6; /* WebKit/Blink Browsers */
  color: #ffffff;
}
::-moz-selection {
  background: #77D9E6; 
  color: #ffffff;/* Gecko Browsers */
}

.tongue-out {
  animation-delay: 1s;
  animation-name: tongue-out-animation;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

@keyframes tongue-out-animation {
  0%   {opacity: 0; height: 0px;}
  10%  {opacity: 1; height: 2px;}
  20%  {opacity: 1; height: 12px; transform: rotate(10deg);}
  25%  {opacity: 1; height: 12px; transform: rotate(17deg);}
  30%  {opacity: 1; height: 12px;}
  35% {opacity: 0; height: 0px;}
  100% {opacity: 0; height: 0px;}
}

.blinker {
  animation-name: blinker-animation;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes blinker-animation {
  0%   {transform: translateY(0px);}
  10%  {transform: translateY(0px);}
  20%  {transform: translateY(100%);}
  25% {transform: translateY(0px);}
  100% {transform: translateY(0px);}
}

.loader {
  width: 22px;
  height: 22px;
  animation: loader-animation 2s linear infinite;
}

@keyframes loader-animation {
  0%   {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

.winking-loader {
  transform-origin: center;
  animation: winking-loader-animation 2s ease-in-out infinite;
}

@keyframes winking-loader-animation {
  0%   {transform: scale3d(0.9,0.9,0.9);}
  50% {transform: scale3d(1,1,1);}
  100%   {transform: scale3d(0.9,0.9,0.9);}
}

.postcard-minimising {
  transform-origin: center;
  animation: minimising-animation 10s ease-in-out forwards;
}

@keyframes minimising-animation {
  0%   {transform: translateX(0) scale3d(1,1,1) translateZ(0);}
  10%   {transform: translateX(0) scale3d(0.2,0.2,0.2)}
  20%   {transform: translateX(0) scale3d(0.25,0.25,0.25)}
  30%   {transform: translateX(0) scale3d(0.2,0.2,0.2)}
  40%   {transform: translateX(0) scale3d(0.25,0.25,0.25)}
  50%   {transform: translateX(0) scale3d(0.2,0.2,0.2)}
  60%   {transform: translateX(0) scale3d(0.25,0.25,0.25)}
  70%   {transform: translateX(0) scale3d(0.2,0.2,0.2)}
  80%   {transform: translateX(0) scale3d(0.25,0.25,0.25)}
  90%   {transform: translateX(0) scale3d(0.2,0.2,0.2)}
  100%   {transform: translateX(0) scale3d(0.2,0.2,0.2)}
}

.postcard-offscreen {
  transform-origin: center;
  animation: offscreen-animation 2s ease-in-out forwards;
}

@keyframes offscreen-animation {
  0%   {transform: translateX(0) scale3d(0.2,0.2,0.2) translateZ(0);}
  100%   {transform: translateX(200%) scale3d(0.2,0.2,0.2)}
}

@media only screen and (max-width: 600px) {
  .postcard-text {
    font-size: 20px;
    line-height: 40px;
  }

  .about-holder {
    text-align: left;
  }

  .postcard {
    height: 87%;
  }

  .big-logo {
    width: 90px;
    height: 90px;
    transform: rotate(-2deg);
    user-select: none;
  }

  .to-when {
    padding-top: 180px;
    justify-content: flex-start;
  }

  .text-field {
    font-size: 16px;
    height: 26px;
    width: 100%;
    margin-top: 20px;
  }

  .field-and-label {
    flex-direction: column;
    align-items: flex-start;
  }

  .date-options {
    margin-top: 20px;
    margin-left: 0px;
  }

}

@media only screen and (min-width: 600px) {
  .rounded-button {
    transition-timing-function: cubic-bezier(0.64, 0.57, 0.67, 1.53);
    transition-duration: 0.1s;
  }

  .rounded-button:hover {
    transform: scale3d(1.2,1.2,1.2);
  }
}
